import {bootstrapModal} from "../lib/bootstrapModal";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {Messages} from "../messages/Messages";
import {apiPost, apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";

const messages = new Messages();

export class SendContainerToWork {
    private containerId: number;

    constructor(containerId: number) {
        this.containerId = containerId;
    }

    execute(): Promise<void> {
        return new Promise((resolve, reject) => {
            apiPostForAjaxResponse(`${AppConfig.CP}/op/sendToWork`, {container: this.containerId})
                .then(res => {
                    if (!res) return Promise.reject(500)

                    if (res.generator) {
                        const {pathVariations, checkedVariations} = res.generator;
                        const checkedPercent = pathVariations > 0 ? Math.round(checkedVariations * 100 / pathVariations) : 0;
                        showToastInfo(messages.get('SendContainerToWork.completeNoticeWithGenerator', checkedVariations, pathVariations, checkedPercent))
                    } else {
                        showToastInfo(messages.get('SendContainerToWork.completeNotice'))
                    }

                    resolve()
                })
                .catch(toastFetchError)
                .then(() => reject());
        })
    }

    showModal(completer?: Function) {
        const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get('SendContainerToWork.confirmNotice'),
            body: '',
            buttonOk: messages.get('button.send')
        });
        modal.show();

        const ok = buttonOk!!;

        ok.addEventListener('click', () => {
            buttonOkClickStart();

            this.execute()
                .then(() => {
                    modal.hide()
                    if (completer) completer()
                })
                .catch(buttonOkClickFinish)
        })
    }
}