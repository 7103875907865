import {Messages} from "../messages/Messages";
import {bootstrapModal} from "../lib/bootstrapModal";
import {escapeHTML} from "../lib/escapeHTML";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {apiPost} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {fetchJson, isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {toggleDisabled, toggleHidden} from "../lib/domFunctions";
import {PositionSelectOption} from "../dto/com.rico.sb2.service.positions";
import {coalesce} from "../lib/coalesce";

const messages = new Messages();

export class SendContainerToLoading {
    private readonly containerId: number

    constructor(containerId: number) {
        this.containerId = containerId
    }

    execute(position: number): Promise<void> {
        return new Promise((resolve, reject) => {
            apiPost(`${AppConfig.CP}/op/sendToLoad`, {container: this.containerId, position})
                .then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
                .then(success => {
                    if (!success) return Promise.reject(500)

                    showToastInfo(messages.get('SendContainerToLoad.completeNotice'))
                    resolve()
                })
                .catch(error => {
                    toastFetchError(error);
                    reject();
                })
        })
    }

    showModal(success?: Function) {
        const {modal, content, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get('viewContainerPage.action.sendToLoad'),
            body: this.renderForm(),
            buttonOk: messages.get('button.send')
        });
        modal.show();

        const ok = buttonOk!!;
        const positionSelect = content.querySelector<HTMLSelectElement>('select[name="position"]')!!;

        function okToggle() {
            toggleDisabled(ok, !isFinite(parseInt(positionSelect.value)))
        }

        positionSelect.addEventListener('change', okToggle);
        okToggle()

        ok.addEventListener('click', () => {
            const position = parseInt(positionSelect.value);
            if (!position) return;

            buttonOkClickStart();

            this.execute(position)
                .then(() => {
                    modal.hide()
                    if (success) success()
                })
                .catch(buttonOkClickFinish)
        })

        function loadingStop() {
            content.querySelector('[data-bind="loading"]')?.remove()
            okToggle()
        }

        fetchJson(`${AppConfig.CP}/op/listLoadTargets`)
            .then((res: any) => isAjaxResponseOk(res) ? res.value : Promise.reject(500))
            .then((positions: PositionSelectOption[]) => {
                if (positions.length > 0) {
                    positionSelect.innerHTML = positions
                        .sort((a, b) => coalesce(a.label, '').localeCompare(b.label))
                        .map((p: any) => `<option value="${p.id}">${p.id}: ${escapeHTML(p.label)}</option>`)
                        .join("")
                    toggleHidden(content.querySelector('[data-bind="select"]')!!, false)
                } else {
                    toggleHidden(content.querySelector('[data-bind="emptyMessage"]')!!, false)
                }
            })
            .catch(toastFetchError)
            .then(loadingStop)
    }

    private renderForm(): string {
        return `
            <div class="text-center" data-bind="loading"><div class="lds-dual-ring"></div></div>        
            <div class="hidden text-center" data-bind="emptyMessage">${escapeHTML(messages.get('SendContainerToLoad.noAvailablePositions'))}</div>
            <div class="hidden" data-bind="select"><select name="position" class="form-select" required></select></div>
        `
    }
}
