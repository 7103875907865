import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {bootstrapModal} from "../lib/bootstrapModal";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export class CancelStarting {
    private readonly containerId: number

    constructor(containerId: number) {
        this.containerId = containerId
    }

    private renderBody() {
        const messageCode = `CancelStarting.confirmBody`;
        return `<div class="text-center">${messages.get(messageCode)}</div>`;
    }

    execute(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            apiPostForAjaxResponse(`${AppConfig.CP}/op/cancelContainerStarting`, {container: this.containerId})
                .then(success => {
                    if (success) {
                        showToastInfo(messages.get('CancelStarting.completeNotice'))
                        resolve(true);
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    toastFetchError(error);
                    reject();
                })
        })
    }

    showModal(completer?: Function) {
        const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get(`CancelStarting.confirmNotice`),
            body: this.renderBody(),
            buttonOk: messages.get('CancelStarting.confirmButton')
        });
        modal.show();

        const ok = buttonOk!!;

        ok.addEventListener('click', () => {
            buttonOkClickStart();

            this.execute()
                .then(newState => {
                    modal.hide()
                    if (completer) completer(newState)
                })
                .catch(buttonOkClickFinish)
        })
    }
}