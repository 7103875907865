import {jQuery} from './boot'
import './lib/bootstrap-autocomplete/main'
import {escapeHTML} from "./lib/escapeHTML"
import {Messages} from "./messages/Messages";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {buttonProgress} from "./lib/buttonProgress";
import {AppConfig} from './AppConfig';
import {DetailSearch} from "./modals/DetailSearch";
import {toastFetchError} from "./lib/fetch";
import {DetailListItem} from "./dto/com.rico.sb2.service.details";
import {createHtmlElement} from "./lib/domFunctions";

const messages = new Messages();

export class CoatingDetailsList {
    private readonly id: number;

    private get tableJq() {
        return jQuery('#table')
    }

    constructor(id: number) {
        this.id = id;

        this.tableJq.bootstrapTable({
            url: `${AppConfig.CP}/coatings/${this.id}/detailsPage`,
            sidePagination: 'server',
            responseHandler: function (res) {
                return {
                    total: res.totalElements,
                    rows: res.content
                }
            },
            columns: [
                {
                    formatter: (value: any, row: any) => {
                        const editLink = `${AppConfig.CP}/details/${row.id}/edit`;
                        return `<a href="${editLink}" >${escapeHTML(row.code)}</a>`;
                    }
                },
                {},
                {},
                {},
                {},
                {
                    class: 'w-10em text-center',
                    formatter: (value: any, row: any) => {
                        return `<button type="button" class="btn btn-danger btn-sm" data-detail="${row.id}" onclick="controller.removeDetailRow(this)">${messages.get('button.remove')}</button>`;
                    }
                }
            ]
        })
    }

    showAddDetails() {
        new DetailSearch({
            selectHandler: item => this.addDetailItem(item)
        });
    }

    private addDetailItem(item: DetailListItem) {
        return apiPostForAjaxResponse(`/coatings/${this.id}/addDetail?detail=${item.id}`)
            .then(success => {
                if (success) {
                    this.tableJq.bootstrapTable('append', item)
                }
                return success
            })
            .catch(error => {
                toastFetchError(error)
                return false
            })
    }

    removeDetailRow(button: HTMLButtonElement) {
        const detail = button.getAttribute('data-detail');
        buttonProgress(button, messages.get('remove.progress'));

        apiPostForAjaxResponse(`/coatings/${this.id}/removeDetail?detail=${detail}`)
            .then(success => {
                if (!!success) {
                    button.before(createHtmlElement('span', {class: 'text-success'}, messages.get('remove.done')));
                    button.remove();

                    button.closest('tr')?.remove()
                }
            })
            .catch(toastFetchError)
    }
}