import {SvgView} from "./SvgView";
import {createHtmlElement, createSvgElement, SVG_NAMESPACE, toggleHidden} from "../lib/domFunctions";
import {ContainerState, ContainerStateValues, ContainerType, ContainerTypeValues} from "../dto/com.rico.sb2.entity.detail";
import {ContainerDataAdapter} from "../dashboard/ContainerDataSupplier";
import {AppConfig} from "../AppConfig";

export interface ContainerViewState {
    data: ContainerDataAdapter

    get percent(): number | null
}

class ContainerIconCache {
    private readonly cached = new Map<ContainerType, SVGElement>()

    getIcon(type: ContainerType): SVGElement | null {
        if (!type) return null;

        if (!this.cached.has(type)) {
            const typeInfo = AppConfig.ContainerTypes.filter(t => t.type == type)[0];
            if (!typeInfo || !typeInfo.iconSvg) return null;

            const template = createHtmlElement('div', {}, typeInfo.iconSvg)
            const svg = template.querySelector('svg') as SVGElement

            const viewBox = svg.getAttribute('viewBox') as string;
            const viewBoxValues = viewBox.split(/[\s,]+/);
            const [, , w, h] = viewBoxValues
            svg.removeAttribute('viewBox')

            const g = createSvgElement("g")
            g.innerHTML = svg.innerHTML
            g.style.transform = `scale(${16 / parseFloat(w)}, ${16 / parseFloat(h)})`

            this.cached.set(type, g)
        }

        return this.cached.get(type)?.cloneNode(true) as SVGElement || null;
    }
}

const containerIconCache = new ContainerIconCache();

export class ContainerView extends SvgView implements ContainerViewState {
    data: ContainerDataAdapter

    private percentBoundValue: string | null = null
    public onPosition: number | null = null
    public onTransport: number | null = null

    private iconBoundType: string | null = null
    private iconBoundSvg: SVGElement | null = null

    constructor(data: ContainerDataAdapter, view: SVGElement | null) {
        super(view)
        this.data = data

        this.view?.setAttribute('id', `con${data.id}`)
        this.update()
    }

    get percent() {
        const data = this.data
        if (data.programFinishTime == null || data.programStartTime == null) return null;

        const period = (data.programFinishTime - data.programStartTime);
        if (period <= 0) return null;

        const currentTime = new Date().getTime();
        if (currentTime < data.programStartTime) return null

        return 100 * (currentTime - data.programStartTime) / period;
    }

    get hidden() {
        return this.data.state === null || this.data.state === ContainerState.PLANNED;
    }

    update() {
        ContainerStateValues.forEach(enumValue => this.view?.classList.remove(enumValue));
        if (this.data.state != null) this.view?.classList.add(this.data.state)
        this.view?.classList.toggle('OVERFLOW', this.data.overexposure)

        // set type class
        ContainerTypeValues.forEach(enumValue => this.view?.classList.remove(enumValue));
        if (this.data.type != null) this.view?.classList.add(this.data.type)

        // set type icon
        if (this.iconBoundType != this.data.type) {
            this.iconBoundSvg?.remove();
            this.iconBoundSvg = null;

            this.iconBoundType = this.data.type;
            const iconBoundSvg = containerIconCache.getIcon(this.data.type)
            if (iconBoundSvg) {
                this.iconBoundSvg = iconBoundSvg
                this.bindWith('icon', svg => svg.append(iconBoundSvg))
            }
        }

        this.bind('number', this.data.number);

        toggleHidden(this.view, this.hidden || (this.data.position == null && this.data.transport == null));

        this.updateText()
    }

    private updateText() {
        const percent = this.percent;
        const percentBoundValue = percent == null ? '' : (Math.round(Math.min(percent, 100)).toString() + '%');
        if (this.percentBoundValue == percentBoundValue) return

        this.percentBoundValue = percentBoundValue
        this.bind('percent', percentBoundValue);
    }

    tick() {
        if (this.data.programStartTime != null) {
            this.updateText();
        }
    }
}
