import {jQuery} from "./boot";
import {Messages} from "./messages/Messages";
import {escapeHTML} from "./lib/escapeHTML";
import {AppConfig} from "./AppConfig";

const messages = new Messages();

export class CoatingListPage {
    private readonly canEdit: boolean;

    constructor(canEdit: boolean) {
        this.canEdit = canEdit;
    }

    start() {
        jQuery('#table').bootstrapTable({
            url: `${AppConfig.CP}/coatings/listPage`,
            sidePagination: 'server',
            responseHandler: function (res) {
                return {
                    total: res.totalElements,
                    rows: res.content
                }
            },
            columns: [
                {},
                {},
                {
                    formatter: (value: any, row: any) => {
                        return `${escapeHTML(row.process)} <span class="text-muted">${escapeHTML(row.processName)}</span>`;
                    }
                },
                {},
                {
                    formatter: (value: any, row: any) => {
                        const buttons = this.canEdit
                            ? `<a href="${AppConfig.CP}/coatings/${row.id}/details" class="ms-3 btn btn-secondary btn-sm text-nowrap">${messages.get("coating.table.viewDetailList")}</a>`
                            : '';
                        return `<span>${row.details}</span>${buttons}`;
                    }
                },
                {
                    class: 'text-end',
                    formatter: (value: any, row: any) => {
                        if (!this.canEdit) return '';
                        return `
                            <a href="${AppConfig.CP}/coatings/${row.id}/delete" class="btn btn-danger btn-sm">${messages.get("button.delete")}</a>
                            <a href="${AppConfig.CP}/coatings/${row.id}/edit" class="btn btn-secondary btn-sm">${messages.get("button.edit")}</a>
                        `
                    }
                }
            ]
        })
    }
}