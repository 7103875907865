import {jQuery} from "./boot";
import {Messages} from './messages/Messages'
import {AppConfig} from "./AppConfig";
import {ContainerTypeNamer} from "./dto/com.rico.sb2.support";

const messages = new Messages();
const containerTypeNamer = new ContainerTypeNamer();

interface ProcessListPageParams {
    canEdit?: boolean
}

export class ProcessListPage {
    private readonly canEdit: boolean;

    constructor({canEdit}: ProcessListPageParams) {
        this.canEdit = !!canEdit
    }

    start() {
        const canEdit = this.canEdit;

        jQuery('#table').bootstrapTable({
            url: `${AppConfig.CP}/processes/listPage`,
            sidePagination: 'server',
            responseHandler(res) {
                return {total: res.totalElements, rows: res.content}
            },
            rowStyle(row) {
                if (row.deleted) {
                    return {classes: 'text-decoration-line-through'};
                }
                return {}
            },
            columns: [
                {},
                {},
                {
                    formatter(value: any, row: any) {
                        return containerTypeNamer.name(row.containerType);
                    }
                },
                {
                    class: 'text-nowrap text-center'
                },
                {
                    class: 'text-nowrap text-center',
                    formatter(value: any, row: any) {
                        return `<span>${row.actionCount}</span>`
                    }
                },
                {
                    class: 'text-nowrap text-end',
                    formatter(value: any, row: any) {
                        if (canEdit && !row.deleted) {
                            return `
                                <a href="${AppConfig.CP}/processes/${row.id}/delete" class="btn btn-danger btn-sm">${messages.get("button.delete")}</a>
                                <a href="${AppConfig.CP}/processes/${row.id}/edit" class="btn btn-secondary btn-sm">${messages.get("button.edit")}</a>`
                        }
                        return '';
                    }
                }
            ]
        })
    }
}