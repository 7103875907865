import {jQuery} from "./boot";
import {AppConfig} from "./AppConfig";
import just from "./lib/just";
import {BindingList, createHtmlElementFromHtml} from "./lib/domFunctions";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import {bootstrapModal} from "./lib/bootstrapModal";
import {toastFetchError} from "./lib/fetch";
import {getContentDispositionName, postForFile} from "./lib/SecuredAjax";

export class ReportListPage {
    private readonly bindings: BindingList;

    constructor() {
        this.bindings = new BindingList();
        this.bindings.collect(document.documentElement)
        this.bindings.collectByName(document.documentElement);

        this.bindings.update<HTMLInputElement>('from', node => this.initDatepicker(node, true))
        this.bindings.update<HTMLInputElement>('till', node => this.initDatepicker(node))

        this.toggleDownload(false);
        this.bindings.update<HTMLInputElement>('download', node => node.addEventListener('click', this.downloadSelection.bind(this)));

        this.tableJq.bootstrapTable({
            url: this.createTableUrl(),
            sidePagination: 'server',
            rowStyle: () => just({classes: 'align-baseline'}),
            onClickRow: row => createHtmlElementFromHtml(`<a target="_blank" href="${AppConfig.CP}/reports/${row.id}"></a>`).click(),
            onPostBody: () => this.toggleDownload(true),
            responseHandler: res => just({total: res.totalElements, rows: res.content}),
            columns: [
                {
                    formatter: (value: any, row: any) => {
                        let containerType = row.containerType;
                        if (!containerType) {
                            containerType = 'NOT_DEFINED';
                        }
                        return `${value}<img src="${AppConfig.CP}/content/icon.${containerType.toLowerCase()}.svg"  class="ms-2" style="height:2em; width: 2em;">`;
                    }
                },
                {},
                {},
                {},
            ]
        })
    }

    private get tableJq() {
        return jQuery('#table');
    }

    private get fromDateString(): string {
        return this.bindings.first<HTMLInputElement>('from')?.value || '';
    }

    private get tillDateInput(): HTMLInputElement {
        return this.bindings.first<HTMLInputElement>('till')!;
    }

    private get tillDateString(): string {
        return this.tillDateInput.value || '';
    }

    private createTableUrl() {
        return `${AppConfig.CP}/reports/listPage?from=${this.fromDateString}&till=${this.tillDateString}`;
    }

    private changeTableUrl() {
        this.toggleDownload(false);

        const table = this.tableJq;
        const options = table.bootstrapTable('getOptions');
        options.url = this.createTableUrl();
        table.bootstrapTable('refreshOptions', options);
        table.bootstrapTable('refresh');
    }

    private initDatepicker(node: HTMLInputElement, copyToTill: boolean = false) {
        const today = new Date();
        const nodeJq = jQuery(node);
        const options = {
            language: AppConfig.locale,
            todayBtn: true,
            todayHighlight: true,
            clearBtn: true
        }

        nodeJq
            .datepicker(options)
            .datepicker('setDate', new Date())
            .datepicker('setEndDate', new Date())
            .on('clearDate', () => this.changeTableUrl())
            .on('changeDate', () => {
                if (copyToTill) {
                    jQuery(this.tillDateInput).datepicker('setDate', nodeJq.datepicker('getDate'));
                }
                this.changeTableUrl();
            })
    }

    private toggleDownload(on: boolean) {
        this.bindings.update<HTMLButtonElement>('download', node => node.disabled = !on);
    }

    private downloadSelection() {
        const totalRows = this.tableJq.bootstrapTable('getOptions').totalRows;
        if (!Number.isFinite(totalRows) || totalRows <= 0) {
            alert('Скачивать нечего, список отчетов пустой');
            return;
        }
        if (totalRows > 100 && !confirm('Список отчетов очень большой. Все равно экспортировать?')) {
            return;
        }

        const {content, modal} = bootstrapModal({
            title: 'Создаем архив...', body: `Если отчетов много, то их генерация может занять некоторое время`,
            closeable: false,
            buttonOk: null, buttonCancel: null
        });

        modal.show();
        const progressModalHide = modal.hide.bind(modal);

        const generateForm = {from: this.fromDateString, till: this.tillDateString};
        let fileName = "";
        postForFile(`${AppConfig.CP}/reports/generateArchive`, generateForm)
            .then(res => {
                fileName = getContentDispositionName(res.headers);
                return res.blob();
            })
            .then(blob => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(blob);
                fileLink.download = fileName;
                fileLink.click();
            })
            .catch(toastFetchError)
            .then(progressModalHide);
    }
}