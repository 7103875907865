import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {bootstrapModal} from "../lib/bootstrapModal";
import {showToastError, showToastSuccess} from "../lib/boostrapToast";

export class CloneContainer {
    private readonly containerId: number

    constructor(containerId: number) {
        this.containerId = containerId
    }

    showModal() {
        const {modal} = bootstrapModal({
            title: 'Клонируем контейнер...', body: `Это может занять некоторое время`,
            closeable: false,
            buttonOk: null, buttonCancel: null
        });

        modal.show();
        const progressModalHide = modal.hide.bind(modal)

        apiPostForAjaxResponse(`${AppConfig.CP}/op/cloneContainer`, {container: this.containerId})
            .then(id => {
                if (!(id > 0)) {
                    showToastError('Клонирование контейнера не удалось');
                } else {
                    showToastSuccess('Создана копия контейнера. Вы можете найти её в списке подвесок "В ожидании".');
                }
            })
            .catch(toastFetchError)
            .then(progressModalHide);
    }
}