import {ContainerListItem} from "../dto/com.rico.sb2.service.queue";
import {BindingList, createHtmlElement, setInnerTextAndTitle} from "../lib/domFunctions";
import {Messages} from "../messages/Messages";
import {ContainerType} from "../dto/com.rico.sb2.entity.detail";
import {coalesce} from "../lib/coalesce";
import {AppConfig} from "../AppConfig";
import {SinglePopover} from "../lib/bootstrapPopover";
import {FinishedClickMenu} from "./FinishedClickMenu";

const messages = new Messages();

const TEMPLATE = `
<span class="d-block text-end" style="width: 2.5em;" data-bind="number"></span>
<img src="" alt="" style="height: 2em; width: 2em;" class="m-2" data-bind="icon">
<div class="text-nowrap" style="width: 16em;">
    <span class="d-block overflow-hidden overflow-ellipsis" data-bind="process"></span>
    <span class="d-block overflow-hidden overflow-ellipsis" data-bind="coating"></span>
</div>
<div class="vr my-1 text-bg-dark"></div>
<div class="flex-fill text-end pe-2">
    <div class="text-nowrap" data-bind="status"></div>
    <div class="text-nowrap" data-bind="finishTime"></div>
</div>
`;

export class FinishedListItem {
    readonly data: ContainerListItem
    readonly element: HTMLDivElement;

    private readonly bindings = new BindingList()

    private clickMenu: FinishedClickMenu | null = null

    constructor(data: ContainerListItem) {
        this.data = data

        this.element = createHtmlElement('div', {role: 'button', tabindex: 0, class: 'border rounded-1 mb-2 mx-0 align-middle container-row-widget d-flex align-items-center container-theme'}, TEMPLATE)
        this.bindings.collect(this.element)

        this.element.addEventListener('click', ev => {
            const menu = this.requireClickMenu()
            const popover = menu.createPopover(this.element);
            SinglePopover.show(this.element, popover, {dispose: true, hidden: () => menu.hidePopover()})
            ev.preventDefault()
        })

        this.update()
    }

    private update() {
        const data = this.data
        this.bindings.update<HTMLAnchorElement>('goto', a => a.href = `${AppConfig.CP}/queue/${data.id}`)

        this.bindings.update('number', node => node.innerText = `${data.id}`)
        this.bindings.update<HTMLImageElement>('icon', node => node.src = `/content/icon.${coalesce(data.type, ContainerType.NOT_DEFINED).toLowerCase()}.svg`)

        this.bindings.update('process', node => setInnerTextAndTitle(node, coalesce(data.processCode, '')))
        this.bindings.update('coating', node => setInnerTextAndTitle(node, coalesce(data.coating, '')))

        this.bindings.update('status', node => node.innerText = data.reportStatus|| '')
        this.bindings.update('finishTime', node => node.innerText = data.finishDate ? data.finishDate : '')
    }

    private requireClickMenu() {
        if (this.clickMenu == null) {
            this.clickMenu = new FinishedClickMenu(this.data);
        }
        return this.clickMenu;
    }
}