import {TransportState} from "./com.rico.sb2.entity.device";
import {ContainerState} from "./com.rico.sb2.entity.detail";
import {Program_State, Program_Type} from "./com.rico.sb2.entity.program";

export interface UpdateSchemeMessage {
    _type: string
}

export function isUpdatePositionMessage(m: UpdateSchemeMessage) {
    return m && m._type == 'P';
}

export interface UpdatePositionMessage extends UpdateSchemeMessage {
    id: number
    active: boolean
    enabled: boolean
    locked: boolean | null
    timeNow: number
    timeLeft: number | null
}

export function isUpdateTransportMessage(m: UpdateSchemeMessage) {
    return m && m._type == 'T';
}

export interface UpdateTransportMessage extends UpdateSchemeMessage {
    id: number
    state: TransportState | null
    position: number | null
    targetPosition: number | null
}

export function isUpdateContainerMessage(m: UpdateSchemeMessage) {
    return m && m._type == 'C';
}

export interface UpdateContainerMessage extends UpdateSchemeMessage {
    id: number
    number: number
    owner: string
    state: ContainerState | null
    position: number | null
    transport: number | null
    /**
     * миллисекунды
     */
    startProgramTime: number | null
    currentTime: number | null
    /**
     * Время, после которого уже считается передержкой. Миллисекунды
     * */
    overexposureTime: number | null

    finishProgramTime: number | null
    finishProgramPosition: number | null

    paIndex: number | null
    processActions: number | null

    lastProgramId: number | null
    lastProgramState: Program_State | null
    lastProgramType: Program_Type | null

    pendingPath: number[] | null
}

interface VisitUpdateSchemeMessageCallbacks<T> {
    positionMessage?: (m: UpdatePositionMessage) => T
    containerMessage?: (m: UpdateContainerMessage) => T
    transportMessage?: (m: UpdateTransportMessage) => T
}

export function orderedUpdateSchemeMessages(m: UpdateSchemeMessage[] | null): UpdateSchemeMessage[] {
    if (m == null || m.length == 0) {
        return []
    }
    const order = new Map<String, number>();
    order.set('P', 1);
    order.set('T', 2);
    order.set('C', 3);
    return m.sort((a, b) => {
        const aOrder = order.get(a._type) || 100;
        const bOrder = order.get(a._type) || 100;
        return aOrder - bOrder;
    })
}

export function visitUpdateSchemeMessage(m: UpdateSchemeMessage, handlers: VisitUpdateSchemeMessageCallbacks<any>) {
    if (!m) return null;

    if (isUpdatePositionMessage(m) && handlers.positionMessage) {
        return handlers.positionMessage(m as UpdatePositionMessage);
    }
    if (isUpdateTransportMessage(m) && handlers.transportMessage) {
        return handlers.transportMessage(m as UpdateTransportMessage);
    }
    if (isUpdateContainerMessage(m) && handlers.containerMessage) {
        return handlers.containerMessage(m as UpdateContainerMessage);
    }
    return null;
}