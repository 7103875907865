import {Messages} from "../messages/Messages";
import {BindingList, createHtmlElementFromHtml, hasNoHidden} from "../lib/domFunctions";
import {Popover, PopoverInstance} from "../lib/bootstrapPopover";
import {PlayerDataAdapter, UpdateSchemeMessageConsumer} from "../PlayerDataAdapter";
import {PositionDataAdapter} from "./PositionDataSupplier";
import {UpdatePositionMessage, UpdateSchemeMessage, visitUpdateSchemeMessage} from "../dto/com.rico.sb2.message";
import {AppConfig} from "../AppConfig";
import {UserRoles} from "../dto/com.rico.sb2.service.users";
import {TelemetryMessageProcessor_BathTelemetry} from "../dto/com.rico.sb2.service";
import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {showToastInfo} from "../lib/boostrapToast";
import {toastFetchError} from "../lib/fetch";

const messages = new Messages();

export class PositionPopover implements PopoverInstance, UpdateSchemeMessageConsumer {
    private readonly playerData: PlayerDataAdapter;

    private readonly data: PositionDataAdapter
    private readonly body: HTMLElement
    private readonly bindings: BindingList;

    constructor(playerData: PlayerDataAdapter, id: number) {
        this.playerData = playerData
        this.data = playerData.getPositionData(id)

        this.body = createHtmlElementFromHtml(`
<div>
    <div class="text-center text-nowrap m-3 fw-bolder" data-bind="name"></div>
    <div class="px-3 my-3">
        <table class="w-100">
            <tr><td class="text-nowrap pe-3">Позиция</td><td class="text-end text-lowercase">№<span data-bind="number"></span></td></tr>
            <tr><td class="text-nowrap pe-3">Статус</td><td class="text-end text-lowercase"><span data-bind="enabled"></span></td></tr>
            <tr><td class="text-nowrap pe-3">Тип</td><td class="text-end text-lowercase"><span data-bind="usesCurrent"></span></td></tr>
        </table>
        <table class="w-100" data-bind="telemetryAll">
            <tr><td colspan="2"><hr></td></tr>
            <tr data-bind="currentRow"><td class="text-nowrap pe-3">Ток</td><td class="text-end"><span data-bind="current"></span> A</td></tr>
            <tr data-bind="voltageRow"><td class="text-nowrap pe-3">Напряжение</td><td class="text-end"><span data-bind="voltage"></span> V</td></tr>
            <tr data-bind="tempRow"><td class="text-nowrap pe-3">Температура</td><td class="text-end"><span data-bind="temp"></span> ℃</td></tr>
        </table>
    </div>
    <div class="text-center m-3">
        <div class="me-n1" role="group" data-bind="buttons">
            <a type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1 hidden" target="_blank" data-bind="unlock">${messages.get('button.unlock')}</a>
            <a type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" target="_blank" data-bind="goto">${messages.get('button.edit')}</a>
        </div>
    </div>
</div>
`)

        this.bindings = new BindingList();
        this.bindings.collect(this.body)

        this.bindings.update<HTMLAnchorElement>('goto', node => node.href = `${AppConfig.CP}/positions/${id}/edit`)
        this.bindings.update<HTMLAnchorElement>('unlock', node => node.addEventListener('click', () => this.unlock()));
    }

    createPopover(onElement: Element, popoverContainer: Element = document.body) {
        this.playerData.updateListeners.add(this)
        this.update();

        return new Popover(onElement, {
            animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.body, container: popoverContainer,
            template: `<div class="popover dashboard-popover" role="popover"><div class="popover-arrow"></div><div class="popover-body p-0"></div></div>`
        });
    }

    hidePopover(): void {
        this.playerData.updateListeners.remove(this)
    }

    onSchemeUpdate(update: UpdateSchemeMessage): void {
        visitUpdateSchemeMessage(update, {
            positionMessage: m => this.onSchemePositionUpdate(m)
        })
    }

    private onSchemePositionUpdate(update: UpdatePositionMessage): void {
        if (update.id != this.data.id) return

        this.update();
    }

    onSchemePositionTelemetry(update: TelemetryMessageProcessor_BathTelemetry): void {
        if (update.id != this.data.id) return

        this.update();
    }

    private update() {
        const data = this.data

        this.bindings.update('name', node => node.innerText = data.name)
        this.bindings.update('number', node => node.innerText = data.id.toString())
        this.bindings.update('enabled', node => node.innerText = messages.get(data.enabled ? `switch.on` : `switch.off`))
        this.bindings.update('usesCurrent', node => node.innerText = messages.get(Number.isFinite(data.maxCurrent) ? `PositionPopover.usesCurrent.yes` : `PositionPopover.usesCurrent.no`))

        const editRoles = new Set([UserRoles.ADMIN, UserRoles.TECH]);
        this.bindings.toggle('buttons', AppConfig.roles.some(e => editRoles.has(e)))

        this.bindings.update('temp', node => node.innerText = data.temperatureString)
        this.bindings.toggle('tempRow', data.temperatureString.length > 0)
        this.bindings.update('current', node => node.innerText = data.currentString)
        this.bindings.toggle('currentRow', data.currentString.length > 0)
        this.bindings.update('voltage', node => node.innerText = data.voltageString)
        this.bindings.toggle('voltageRow', data.voltageString.length > 0)
        this.bindings.toggle('telemetryAll', this.bindings.someAny(['voltageRow', 'currentRow', 'tempRow'], hasNoHidden))

        const canUnlock = data.locked
            && (data.loadingPosition || data.unloadingPosition)
            && AppConfig.roles.some(e => e == UserRoles.ADMIN);
        this.bindings.toggle('unlock', canUnlock)
    }

    private unlock() {
        apiPostForAjaxResponse(`${AppConfig.CP}/op/plcUnlockPosition`, {position: this.data.id})
            .then(success => {
                if (!success) return Promise.reject(500)

                showToastInfo(messages.get('PositionPopover.completePlcUnlockPosition'))
            })
            .catch(toastFetchError);
    }
}