import {Messages} from "../messages/Messages";
import {apiPost, apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {fetchJsonForApiResponse, isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {bootstrapModal} from "../lib/bootstrapModal";
import {toggleDisabled, toggleHidden} from "../lib/domFunctions";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {escapeHTML} from "../lib/escapeHTML";

const messages = new Messages();

export class ChangeContainerStep {
    private readonly container: number

    constructor(container: number) {
        this.container = container
    }

    execute(paIndex: number): Promise<void> {
        return new Promise((resolve, reject) => {
            apiPostForAjaxResponse(`${AppConfig.CP}/op/changeContainerStep`, {container: this.container, paIndex})
                .then(success => {
                    if (!success) return Promise.reject(500)

                    showToastInfo(messages.get('ChangeContainerStep.completeNotice'))
                    resolve()
                })
                .catch(error => {
                    toastFetchError(error);
                    reject();
                })
        })
    }

    showModal(success?: Function) {
        const {modal, content, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get('ChangeContainerStep.title'),
            body: this.renderForm(),
            buttonOk: messages.get('button.save')
        });
        modal.show();
        toggleHidden(content.querySelector('.modal-footer'), true);

        const ok = buttonOk!!;

        const paIndexSelectContainer = content.querySelector<HTMLElement>('[data-bind="form"]')!!;

        const checkedPaIndex = () => {
            return paIndexSelectContainer.querySelector<HTMLSelectElement>('input[name="paIndex"]:checked');
        }

        function okToggle() {
            const checkedPaIndexInput = checkedPaIndex();
            toggleDisabled(ok, checkedPaIndexInput == null || !isFinite(parseInt(checkedPaIndexInput.value)));
        }

        okToggle();

        ok.addEventListener('click', () => {
            const paIndex = parseInt(checkedPaIndex()!.value);
            if (!isFinite(paIndex)) return;

            buttonOkClickStart();

            this.execute(paIndex)
                .then(() => {
                    modal.hide()
                    if (success) success()
                })
                .catch(buttonOkClickFinish)
        })

        function loadingStop() {
            content.querySelector('[data-bind="loading"]')?.remove()
            okToggle()
        }

        fetchJsonForApiResponse(`${AppConfig.CP}/op/listChangeContainerStepOptions?container=${this.container}`)
            .then((positions: { paIndex: number, order: number, positionTitle: String, positionShortTitle: String, highlight: boolean, current: boolean }[]) => {
                if (positions.length > 0) {
                    paIndexSelectContainer.innerHTML = positions
                        .sort((a, b) => a.order - b.order)
                        .map((p, index) => `
<div class="form-check mb-2">
  <input class="form-check-input" type="radio" name="paIndex" id="paIndex${index}" value="${p.paIndex}" ${p.current ? 'checked' : ''}>
  <label class="form-check-label ${p.highlight ? ' text-success ' : ''} cursor-pointer" for="paIndex${index}">${escapeHTML(p.positionTitle)}</label>
</div>
`)
                        .join("")
                    paIndexSelectContainer.querySelectorAll<HTMLSelectElement>('input[name="paIndex"]').forEach(input => input.addEventListener('click', okToggle));
                    toggleHidden(content.querySelector('[data-bind="select"]')!!, false)
                } else {
                    toggleHidden(content.querySelector('[data-bind="emptyMessage"]')!!, false)
                }
                toggleHidden(content.querySelector('.modal-footer'), false);
            })
            .catch(toastFetchError)
            .then(loadingStop)
    }

    private renderForm(): string {
        return `
            <div class="text-center" data-bind="loading"><div class="lds-dual-ring"></div></div>        
            <div class="hidden text-center" data-bind="emptyMessage">${escapeHTML(messages.get('ChangeContainerStep.noOptions'))}</div>
            <div class="hidden" data-bind="select">
                <div data-bind="form" style="max-height: 80vh; overflow: auto;"></div>
            </div>
        `
    }
}
